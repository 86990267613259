<template>
  <div class="bgcard">
    <div class="headerMsg">
      <button class="addbtn" @click="submitOrder">
        <span>+ {{ $t("lang.submitWorkOrder") }}</span>
      </button>
      <div class="searchlist">
        <div class="searchtime" style="margin-right: 40px">
          <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            :range-separator="$t('lang.to')"
            :start-placeholder="$t('lang.Startdate')"
            :end-placeholder="$t('lang.Enddate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="picker"
          >
          </el-date-picker>
        </div>
        <div class="select_b" style="margin-right: 20px">
          <span style="color: #bbbbbb">{{ $t("lang.workOrderStatus") }}：</span>
          <el-select
            style="height: 40px; width: 110px"
            v-model="value1"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="stauts"
          >
            <el-option :label="$t('lang.whole')" value=""></el-option>
            <el-option :label="$t('lang.Assigned')" :value="1"></el-option>
            <el-option :label="$t('lang.Closed')" :value="2"></el-option>
            <el-option :label="$t('lang.Tobeclosed')" :value="3"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="id"
          :label="$t('lang.workOrderNo')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.NameModification')"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span class="texts">{{ scope.row.description | descr }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.problemDescription')" align="center">
          <template #default="scope">
            <span v-if="scope.row.type == 1">
              {{ $t("lang.userRegistrationClass") }}
            </span>
            <span v-if="scope.row.type == 2">
              {{ $t("lang.liveBroadcastTerminalClass") }}
            </span>
            <span v-if="scope.row.type == 3">
              {{$t("lang.streamingMediaPushClass")}}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.state')" align="center">
          <template #default="scope">
            <span v-if="scope.row.state == 1">
              {{ $t("lang.Assigned") }}
            </span>
            <span v-if="scope.row.state == 2">
              {{ $t("lang.Closed") }}
            </span>
            <span v-if="scope.row.state == 3">
              {{ $t("lang.Tobeclosed") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('lang.submissionTime')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="left"
        >
          <template #default="scope">
            <el-button
              size="small"
              class="buttons"
              style="background-color: #1061c5"
              @click="handleEdit(scope.row)"
              >{{ $t("lang.details") }}
              <div class="messige" v-if="scope.row.readStatus == 0"></div>
            </el-button>
            <el-button
              size="small"
              class="buttons"
              v-if="scope.row.state != 2"
              style="background-color: #307b37"
              @click="closemsg(scope.row)"
              >{{ $t("lang.closeWorkOrder") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right" class="paginat" v-if="tableData.list && tableData.list.length > 0">
        <div class="total"><span>{{$t("lang.Total")}}</span> {{tableData.total}} <span>{{$t("lang.Strip")}}</span></div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as workmsg from "@/api/manage.js";
export default {
  data() {
    return {
      time: "",
      value1: "",
      tableData: [],
      parsm: {
        page: 1,
        size: 10,
        state: "",
        createTime: "",
        stopTime: "",
        userId: "",
      },
    };
  },
  mounted() {
    this.parsm.userId = JSON.parse(sessionStorage.getItem("user")).id;
    this.getList();
  },
  filters: {
    descr(val) {
      let data = JSON.parse(val);
			//console.log(data)
      return data[data.length-1].description;
    },
  },
  methods: {
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.createTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.createTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //状态筛选
    stauts(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.state = e;
      this.getList();
    },
    //提交工单
    submitOrder() {
      this.$router.push({
        path: "submitorder",
      });
    },
    //获取工单列表
    async getList() {
      let res = await workmsg.workList(this.parsm);
      console.log(res);
      if (res.data) {
        this.tableData = res.data;
      }
    },
    //详情
    handleEdit(row) {
      this.$router.push({
        path: "workdetails",
        query: { id: row.id },
      });
    },
    //关闭工单
    closemsg(row) {
      workmsg.closeWork({ id: row.id }).then((res) => {
        this.$message.success(this.$t("lang.Operations"));
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
	min-height: calc(100vh - 1.35rem);
	background: #222628;
	border-radius: 0.02rem;
	padding: .3rem;
	box-sizing: border-box;
}
.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    border: none;
    outline-style: none;
    background: #307b37;
    border-radius: .1rem;
    padding: .05rem .1rem .05rem .05rem;
    font-size: .17rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    span {
      margin-left: .06rem;
    }
  }
}
/deep/ .el-table{
	background-color: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 4px;
  font-size: .16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.messige {
  position: absolute;
  width: .10rem;
  height: .10rem;
  background: red;
  top: .10rem;
  right: .09rem;
  border-radius: 50%;
}
.texts {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
/deep/.paginat {
  display: flex;
  .total{
    width: auto;
    height: .42rem !important;
    line-height: .42rem;
    padding: 0px .15rem;
    margin-top: 2px;
    box-sizing: border-box;
    background: #222628;
    border: 1px solid #464748;
    border-radius: 2px;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #B2B2B2;
  }
  .el-pagination span{
    width: auto;
    padding: .06rem .05rem;
    line-height: .28rem;
  }
}
/deep/.el-date-editor{
  .el-range__icon{
    line-height: .28rem;
  }
  .el-range-input{
    font-size: .14rem;
  }
  .el-range-separator{
    line-height: .27rem;
    font-size: .14rem;
  }
  .el-range__close-icon{
    font-size: .14rem;
    width: .25rem;
    line-height: .3rem;
  }
}
</style>
